import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {faShieldCheck} from '@fortawesome/pro-solid-svg-icons/faShieldCheck'
import {DialogService} from '../../../../craft/dialog/dialog.service'
import {TranslocoDirective, TranslocoService} from '@ngneat/transloco'
import {HappinessDialogComponent} from '../../../../pages/public/home-page/banner/happinees-dialog/happiness-dialog.component'
import {SellingPointBarModule} from '../../../../craft/selling-point-bar/selling-point-bar.module'
import {GoogleRatingComponent} from '../../../../craft/google-rating/google-rating.component'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {BrandingModule} from '../../branding/branding.module'
import {ListModule} from '../../../../craft/list/list.module'

@Component({
  selector: 'bgo-hero-selling-point',
  templateUrl: './hero-selling-point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SellingPointBarModule,
    GoogleRatingComponent,
    FaIconComponent,
    BrandingModule,
    ListModule,
    TranslocoDirective,
  ],
  standalone: true,
})
export class HeroSellingPointComponent {
  @Input() align: 'center' | 'left' = 'left'

  readonly happinessIcon = faShieldCheck

  constructor(
    private readonly dialogService: DialogService,
    private readonly translationService: TranslocoService,
  ) {}

  showHappinessDialog(): void {
    this.dialogService.show(HappinessDialogComponent, {
      text: this.translationService.translate('shared.sellingPoints.happiness.modal.text'),
    })
  }
}
