<ng-container *transloco="let t">
  <!--    desktop selling points-->
  <div class="hidden lg:block">
    <cft-selling-point-bar class="py-xs" [hasSolidBackground]="true" mdGapSize="2xl" [align]="align">
      <cft-selling-point-item>
        <div class="flex">
          <cft-google-rating
            class="inline-flex text-primary"
            [numReviews]="t('siteRating.numReviews')"
            [rating]="t('siteRating.rating')"
          ></cft-google-rating>
        </div>
      </cft-selling-point-item>
      <cft-selling-point-item class="text-nowrap">
        <p (click)="showHappinessDialog()" class="cursor-pointer">
          {{ t('shared.sellingPoints.guaranteeText') }}
          <fa-icon [icon]="happinessIcon"></fa-icon>
        </p>
      </cft-selling-point-item>
      <cft-selling-point-item>
        <div class="flex min-w-[140px] flex-col items-center justify-center text-nowrap lg:flex-row">
          <p>{{ t('shared.mobiPartnership.title') }}</p>
          <bgo-mobiliar-logo size="xs" [negative]="true" class="min-w-[64px] pl-2xs"></bgo-mobiliar-logo>
        </div>
      </cft-selling-point-item>
    </cft-selling-point-bar>
  </div>

  <!--    mobile carousel-->
  <div class="w-full text-white lg:hidden">
    <cft-list-carousel
      class="border-t border-white bg-president-400 [&_.btn-ghost:hover]:bg-president-500 [&_.btn-ghost]:mx-xs"
      navigationType="ghost"
      navigationColor="text-white"
      [hasShadow]="false"
    >
      <div cftListCarouselSlide class="flex flex-col items-center justify-center py-xs">
        <cft-google-rating
          class="text-primary"
          [rating]="t('siteRating.rating')"
          [numReviews]="t('siteRating.numReviews')"
        ></cft-google-rating>
      </div>
      <div class="flex items-center justify-center py-xs text-center" cftListCarouselSlide>
        <p (click)="showHappinessDialog()" class="w-8/12 cursor-pointer">
          {{ t('shared.sellingPoints.guaranteeText') }}
          <fa-icon [icon]="happinessIcon"></fa-icon>
        </p>
      </div>
      <div class="flex flex-col items-center justify-center py-sm" cftListCarouselSlide>
        <p>{{ t('offeringDirectory.sellingPoints.mobiText') }}</p>
        <bgo-mobiliar-logo size="xs" [negative]="true" class="pl-2xs"></bgo-mobiliar-logo>
      </div>
    </cft-list-carousel>
  </div>
</ng-container>
